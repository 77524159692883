import React, { useEffect, useState } from 'react'
import IELTStestPageListening from './listening_question/IELTStestPageListening'
import IELTStestPageReading from './reading_question/IELTStestPageReading'


const HomeTestFreePage = () => {
    const [page, setPage] = useState(1)
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            return (event.returnValue = ''); 
          }
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    },[])
  return (
    <>
    {
        page === 1 ? (<IELTStestPageListening nextPage={setPage} />) : 
        page === 2 ? (<IELTStestPageReading nextPage={setPage} />) : ''
    }
    </>
  )
}

export default HomeTestFreePage